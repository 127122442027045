import 'react-phone-input-2/lib/plain.css';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { ESegmentCategory, track } from 'services/analytics';
import AngleButton from 'components/AngleButton';
import config from '../../config';

export default function PhoneInputComponent() {
  const router = useRouter();
  const [t] = useTranslation('common');
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumberString;
  };

  useEffect(() => {
    if (router.query?.phonenumber) {
      setPhone(formatPhoneNumber(router.query.phonenumber as string).trim());
    }
  }, [router.query]);

  const onChangePhoneHandler = useCallback(
    (inputEvent: React.ChangeEvent<HTMLInputElement>) => {
      const phoneValue = inputEvent.target.value;

      if (!phoneValue) {
        setPhone('');
      } else {
        setPhone(formatPhoneNumber(phoneValue));
      }
    },
    []
  );

  const onStartHandler = useCallback(() => {
    const validatePhoneNumber = (phone: string) => {
      const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return re.test(phone);
    };

    if (!phone || !validatePhoneNumber(phone)) {
      setErrorMessage('Phone must have a valid format. Eg.: (123) 456-7890');
      return;
    }
    const searchParams = new Map();
    searchParams.set('phonenumber', `${phone.replace(/\D/g, '')}`);
    searchParams.set('submit', true);
    if (router.query) {
      Object.keys(router.query).forEach((key) =>
        searchParams.set(key, encodeURIComponent(router.query[key] as string))
      );
    }
    const searchParamsString = Array.from(searchParams.keys())
      .map((key) => `${key}=${searchParams.get(key)}`)
      .join('&');

    try {
      const cioParam = searchParams.get('cio_id')
        ? {
            userId: `cio_${searchParams.get('cio_id')}`,
          }
        : {};
      track(ESegmentCategory.CLICKED_SUBMIT, {
        ...Object.fromEntries(searchParams),
        ...cioParam,
      });
    } catch (e) {
      console.error(e);
    }

    router.push(`${config.signInDriver}?${searchParamsString}`);
  }, [router, phone]);

  return (
    <div>
      <div className="my-2 mt-lg-4 d-flex">
        <input
          className="input-phone"
          placeholder="Phone Number"
          type="text"
          inputMode="numeric"
          onChange={onChangePhoneHandler}
          value={phone}
        />
        <div className="phone-input-button">
          <AngleButton label={t('signUp')} onClick={onStartHandler} />
        </div>
      </div>
      {errorMessage?.length > 0 && (
        <label className="input-phone-error">{errorMessage}</label>
      )}
    </div>
  );
}
