import { useTranslation } from 'react-i18next';
import Image from 'next/image';

import HeadComponent from 'components/Head';
import MainLayout from 'components/MainLayout';
import PhoneInputComponent from 'components/PhoneInput';
import Benefits from 'components/Benefits';
import { useInitPosition } from 'public/scripts/common';
import Testimonials from 'components/Testimonials';
import StatesWithJobs from 'components/StatesWithJobs';
import { useEffect } from 'react';
import { ESegmentCategory, track } from 'services/analytics';
import { useRouter } from 'next/router';

export default function DriversOld() {
  const router = useRouter();
  const [t] = useTranslation('driver');
  useInitPosition();

  useEffect(() => {
    if (Object.keys(router.query).length) {
      track(ESegmentCategory.VISIT_LANDING_WITH_LEADS, {
        ...router.query,
      });
    }
  }, [router.query, track]);

  return (
    <>
      <HeadComponent
        title={t('head.title')}
        description={t('head.description')}
      />
      <MainLayout>
        <div>
          {/* Main Landing Page */}
          <div className="container-fluid container-first driver-top-container">
            <div className="row mt-0">
              <div className="col-12">
                <div className="row row-block">
                  <div className="col-12 col-lg-6 driver-signup-number-container">
                    <div className="driver-signup-number">
                      <h1 className="banner-title">{t('bannerTitle')}</h1>
                      <div className="w-100">
                        <PhoneInputComponent />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 p-0 pt-lg-4 driver-top-image-container">
                    <div className="driver-top-image">
                      <div>
                        <Image
                          src="/images/driver/haul-hero.jpg"
                          alt="CDL Driver"
                          width="100"
                          height="100"
                          objectFit="cover"
                          layout="responsive"
                          priority
                        />
                      </div>
                      <div>
                        <Image
                          src="/images/driver/phone-hand.png"
                          alt="Haul App"
                          width="100"
                          height="100"
                          objectFit="contain"
                          layout="responsive"
                          priority
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Second Section */}
          <div className="container-second driver-container-second with-background">
            <div className="container">
              <div className="row row-block">
                <div className="col-12 col-lg-6">
                  <h2 className="howHaulWorks">
                    <span>{t('whyJoinHaul.titleP1')} </span>
                    <span className="highlight-container">
                      <span className="highlight">
                        {t('whyJoinHaul.titleP2')}{' '}
                      </span>
                    </span>
                    <span> {t('whyJoinHaul.titleP3')} </span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="pb-5">
              <div className="container">
                <Benefits />
              </div>
            </div>

            <div className="storeIconContainer">
              <a href="https://apps.apple.com/us/app/haul-driver/id1524004807">
                <Image
                  src="/images/app-stores/app-store-black.svg"
                  alt="App Store"
                  width={149}
                  height={52}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.haul">
                <Image
                  src="/images/app-stores/google-store-black.svg"
                  alt="Google Play Store"
                  width={162}
                  height={52}
                />
              </a>
            </div>
          </div>

          {/* How Haul Works Section */}
          <div className="about-haul-container">
            <div className="container">
              <div className="row">
                <div className="col mt-5 mb-4 mb-lg-5">
                  <h2 className="banner-title">{t('howItWorks.title')}</h2>
                </div>
              </div>
              <div className="wrap-how-work-items">
                <div className="row row-block br1">
                  <div className="col car-item">
                    <div className="row">
                      <div className="col-12 d-flex col-lg-7 offset-lg-3 offset-xl-5">
                        <span className="road-number">#1</span>
                        <div>
                          <span className="car-item-title">
                            {t('howItWorks.first.title')}
                          </span>
                          <div className="swg-road1 d-none d-lg-block">
                            <svg
                              width="510"
                              height="439"
                              viewBox="0 0 479 439"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                id="road1"
                                d="M3 3V238.274C3 254.843 16.4315 268.274 33 268.274H446C462.569 268.274 476 281.706 476 298.274V436"
                                stroke="#f0ff69"
                                strokeWidth="5"
                                strokeLinecap="round"
                                strokeDasharray="1 15"
                              />
                              <image
                                width="25"
                                height="62"
                                x="-13px"
                                y="-31px"
                                href="/images/car.png"
                                id="imagedot1"
                                preserveAspectRatio="none"
                                transform="translate(1297,398.5)"
                                style={{ display: 'block' }}
                              />
                            </svg>
                            <svg
                              width="490"
                              height="492"
                              viewBox="0 0 511 535"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            />
                          </div>
                          <p className="sub-title">
                            {t('howItWorks.first.text')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg">
                    <div className="block-img">
                      <Image
                        src="/images/driver/driverLicense.svg"
                        alt="haul"
                        width={460}
                        height={252}
                        objectFit="contain"
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-block br2">
                  <div className="col-12 col-lg order-last order-lg-first col-schedule-img text-lg-right">
                    <div className="block-img d-inline-block">
                      <Image
                        src="/images/driver/calendar.svg"
                        alt="haul"
                        width={460}
                        height={380}
                        objectFit="contain"
                      />
                    </div>
                  </div>
                  <div className="col order-first order-lg-last car-item">
                    <div className="row">
                      <div className="col-12 d-flex col-lg-7 offset-lg-3 offset-xl-3 road-block-2">
                        <span className="road-number">#2</span>
                        <div className="car-item-second">
                          <span className="car-item-title">
                            {t('howItWorks.second.title')}
                          </span>
                          <p className="sub-title">
                            {t('howItWorks.second.text')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-block">
                  <div className="col car-item">
                    <div className="row">
                      <div className="col-12 d-flex col-lg-7 offset-lg-3 offset-xl-5">
                        <span className="road-number">#3</span>
                        <div className="car-item-third">
                          <span className="car-item-title">
                            {t('howItWorks.third.title')}
                          </span>
                          <div className="swg-road2 d-none d-lg-block">
                            <svg
                              width="510"
                              height="323"
                              viewBox="0 0 479 323"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                id="road2"
                                d="M3 320V268.231C3 251.662 16.4315 238.231 33 238.231H446C462.569 238.231 476 224.799 476 208.231V3"
                                stroke="#f0ff69"
                                strokeWidth="5"
                                strokeLinecap="round"
                                strokeDasharray="1 15"
                              />
                            </svg>
                          </div>
                          <p className="sub-title">
                            {t('howItWorks.third.text')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg br3">
                    <div className="block-img">
                      <Image
                        src="/images/driver/twicCard.svg"
                        alt="haul"
                        width={462}
                        height={325}
                        objectFit="contain"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Testimonial Section */}
            <div className="container testimonials-container">
              <div className="testimonials-section">
                <Testimonials />
              </div>
            </div>
          </div>

          {/* Always on Hand Section */}
          <StatesWithJobs />
        </div>
      </MainLayout>
    </>
  );
}
